<template>
    <div class="container">
        <el-row :gutter="20">
            <el-col :span="24"> 
                <div class="con-box">
                    <el-descriptions title="基本信息" :column="4">
                        <el-descriptions-item label="姓名">{{proxyInfo.proxy.name}}</el-descriptions-item>

                        <el-descriptions-item label="上级代理">{{proxyInfo.proxy.p_name}}</el-descriptions-item>

                        <el-descriptions-item label="等级">{{proxyInfo.proxy.level_name}}</el-descriptions-item>

                        <el-descriptions-item label="状态">
                            <el-tag size="small">{{proxyInfo.proxy.status_name}}</el-tag>
                        </el-descriptions-item>

                        <el-descriptions-item label="手机号">{{proxyInfo.proxy.mobile}}</el-descriptions-item>
                        <el-descriptions-item label="加入时间">{{proxyInfo.proxy.created_at}}</el-descriptions-item>
                        <el-descriptions-item label="代理区域">{{proxyInfo.proxy.address}}</el-descriptions-item>
                    </el-descriptions>
                    <div class="con-box-qrcode">
                        <el-button type="primary" size="mini" @click="handleQrcode">二维码</el-button>
                    </div>
                </div>
            </el-col>
        </el-row>
        <el-row :gutter="20">
            <el-col :span="16"> 
                <div class="con-box" style="height: 160px;">
                    <div class="con-box-yeji">
                        <div class="con-box-yeji-title">
                            <span class="con-box-yeji-title-txt">业绩统计</span>    
                            <span class="con-box-yeji-title-date">截止至{{dateText}}</span>    
                        </div>
                        <div class="con-box-yeji-info" @click="handleReport">查看详情</div>
                    </div>
                    <div class="con-box-bussiness">
                        <div class="con-box-bussiness-item">
                            <div class="con-box-bussiness-item-num">{{proxyInfo.report.diag_amount}}</div>
                            <div class="con-box-bussiness-item-txt">累计问诊金额(元)</div>
                        </div>
                        <div class="con-box-bussiness-item">
                            <div class="con-box-bussiness-item-num">{{proxyInfo.report.pres_amount}}</div>
                            <div class="con-box-bussiness-item-txt">累计其他金额(元)</div>
                        </div>
                        <div class="con-box-bussiness-item">
                            <div class="con-box-bussiness-item-num">
                                <el-link style="font-size: 18px;font-weight: bold;color: #000;" @click="handleGoDoc">{{proxyInfo.report.doc_count}}</el-link>
                               
                            </div>
                            <div class="con-box-bussiness-item-txt">累计签约医师</div>
                        </div>
                        <div class="con-box-bussiness-item">
                            <div class="con-box-bussiness-item-num">
                                <div v-if="$store.state.user.level == 3">0</div>
                                <div v-else>
                                    <el-link style="font-size: 18px;font-weight: bold;color: #000;" @click="handleGoProxy"> {{proxyInfo.report.proxy_count}}</el-link>
                                </div>
                               </div>
                            <div class="con-box-bussiness-item-txt">下级代理总数</div>
                        </div>
                    </div>
                </div>
            </el-col>
            <el-col :span="8">
                <div class="con-box" style="height: 160px;">
                     <div class="con-box-yeji" style="margin-bottom: 5px;">
                        <div class="con-box-yeji-title">
                            <span class="con-box-yeji-title-txt">我的银行卡</span>       
                        </div>
                        <el-button  v-if="proxyInfo.bank" type="primary" size="mini"  @click="handleBank('edit')">编辑</el-button>
                        <!-- <div v-if="proxyInfo.bank" class="con-box-yeji-info" @click="handleBank('edit')">编辑</div> -->
                    </div>
                    <el-descriptions title="" :column="1" v-if="proxyInfo.bank">
                        <el-descriptions-item label="银行名称">{{proxyInfo.bank.bank_name}}</el-descriptions-item>
                        <el-descriptions-item label="银行卡号">{{proxyInfo.bank.card_no}}</el-descriptions-item>
                        <el-descriptions-item label="持卡人姓名">{{proxyInfo.bank.card_name}}</el-descriptions-item>
                    </el-descriptions>
                    <div style="width: 100%;padding:10px 0;display: flex;justify-content: center;" v-else>
                        <el-button size="mini" type="primary" style="margin-top: 25px;" @click="handleBank('add')">添加银行卡</el-button>
                    </div>
                   
                </div>
            </el-col>

        </el-row>


        <el-row :gutter="20">
            <el-col :span="8"> 
                <div class="con-box" style="height: 160px;">
                    <div class="con-box-yeji">
                        <div class="con-box-yeji-title">
                            <span class="con-box-yeji-title-txt">本月经营情况</span>    
                   
                        </div>
                        <div class="con-box-yeji-info" @click="handleReportMonth">查看详情</div>
                    </div>
                    <div class="con-box-bussiness">
                        <div class="con-box-bussiness-item">
                            <div class="con-box-bussiness-item-num">{{proxyInfo.docReportMonth.diag_amount}}</div>
                            <div class="con-box-bussiness-item-txt">问诊金额(元)</div>
                        </div>
                        <div class="con-box-bussiness-item">
                            <div class="con-box-bussiness-item-num">{{proxyInfo.docReportMonth.pres_amount}}</div>
                            <div class="con-box-bussiness-item-txt">其他金额(元)</div>
                        </div>
                        
                    </div>
                </div>
            </el-col>
             <el-col :span="8"> 
                <div class="con-box" style="height: 160px;">
                    <div class="con-box-yeji">
                        <div class="con-box-yeji-title">
                            <span class="con-box-yeji-title-txt">我的提现</span>    
                            <span class="con-box-yeji-title-date" style="color:#999;cursor:pointer" @click="handleBills">查看详情</span>    
                        </div>
                        <div class="con-box-yeji-info" @click="handleTx">去提现</div>
                    </div>
                    <div class="con-box-bussiness">
                        <div class="con-box-bussiness-item">
                            <div class="con-box-bussiness-item-num">{{proxyInfo.bills.billAmount}}</div>
                            <div class="con-box-bussiness-item-txt">累计提现金额(元)</div>
                        </div>
                        <div class="con-box-bussiness-item">
                            <div class="con-box-bussiness-item-num">{{proxyInfo.bills.billCount}}</div>
                            <div class="con-box-bussiness-item-txt">提现次数</div>
                        </div>
                        <div class="con-box-bussiness-item">
                            <div class="con-box-bussiness-item-num">{{proxyInfo.bills.enableAmount}}</div>
                            <div class="con-box-bussiness-item-txt">可提现金额(元)</div>
                        </div>
                        
                    </div>
                </div>
            </el-col>
             <el-col :span="8"> 
                <div class="con-box" style="height: 160px;">
                    <div class="con-box-yeji">
                        <div class="con-box-yeji-title">
                            <span class="con-box-yeji-title-txt">收益明细</span>    
                   
                        </div>
                        <div class="con-box-yeji-info" @click="handleIncome">查看详情</div>
                    </div>
                    <div class="con-box-bussiness">
                        <div class="con-box-bussiness-item">
                            <div class="con-box-bussiness-item-num">{{proxyInfo.income.monthAmount}}</div>
                            <div class="con-box-bussiness-item-txt">当月收益(元)</div>
                        </div>
                        <div class="con-box-bussiness-item">
                            <div class="con-box-bussiness-item-num">{{proxyInfo.income.totalAmount}}</div>
                            <div class="con-box-bussiness-item-txt">累计收益(元)</div>
                        </div>
                        
                    </div>
                </div>
            </el-col>
        </el-row>


        <el-dialog title="二维码" :visible.sync="dialogQrcodeVisible" width="300px">
            <div style="display: flex;justify-content: center;">
                <el-image style="width: 200px; height: 200px;"
                :src="proxyInfo.proxy.qrcode"
                fit="fill">
            </el-image> 
            </div>
            
            <div slot="footer" class="dialog-footer" style="display: flex;justify-content: center;">
                <el-button type="primary" @click="saveQrcode">保存二维码</el-button>
            </div>    
        </el-dialog>
        <el-dialog title="银行卡" :visible.sync="dialogBankVisible" width="500px">
            <el-form ref="form" :model="bankForm" :rules="rules" label-width="100px">
                <el-form-item label="银行卡" prop="bank_id">
                     <el-select v-model="bankForm.bank_id" placeholder="请选择">
                        <el-option
                            v-for="item in bankOptions"
                            :key="item.id"
                            :label="item.text"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="银行卡号" prop="card_no">
                     <el-input type="number" v-model="bankForm.card_no"></el-input>
                </el-form-item>

                 <el-form-item label="持卡人姓名" prop="card_name">
                     <el-input  v-model="bankForm.card_name"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitForm()">确认</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="医生业绩" :visible.sync="dialogDocReportVisible">
            <el-table
                v-loading="docLoading"
                :data="tableData"
                border
                style="width: 100%">
                <el-table-column
                prop="id"
                label="医师id"
                align="center"
                width="80">
                </el-table-column>
                <el-table-column
                prop="doctor_name"
                align="center"
                label="姓名"
                width="120">
                </el-table-column>
                <el-table-column
                prop="sign_at"
                width="150"
                align="center"
                label="签约时间">
                </el-table-column>
                <el-table-column
                prop="diagnosis_count"
                align="center"
                label="累计问诊数">
                </el-table-column>
                <el-table-column
                prop="diagnosis_amount"
                align="center"
                width="150"
                label="累计问诊金额">
                </el-table-column>
                <el-table-column
                prop="pres_count"
                align="center"
                label="累计处方数">
                </el-table-column>
                <el-table-column
                prop="pres_amount"
                align="center"
                width="150"
                label="累计处方金额">
                </el-table-column>
            </el-table>
            <pagination v-show="total>0" :total="total" :page.sync="docReportQuery.page" :limit.sync="docReportQuery.limit"
									@pagination="_getDocReportList"/>
        </el-dialog>



        <el-dialog title="本月经营情况" :visible.sync="dialogDocReportMonthVisible">
            <el-table
                v-loading="docMonthLoading"
                :data="tableDataMonth"
                border
                style="width: 100%">
                <el-table-column
                prop="id"
                label="医师id"
                align="center"
                width="80">
                </el-table-column>
                <el-table-column
                prop="doctor_name"
                align="center"
                label="姓名"
                width="120">
                </el-table-column>
                <el-table-column
                prop="sign_at"
                width="150"
                align="center"
                label="签约时间">
                </el-table-column>
                <el-table-column
                prop="diagnosis_count"
                align="center"
                label="累计问诊数">
                </el-table-column>
                <el-table-column
                prop="diagnosis_amount"
                align="center"
                width="150"
                label="累计问诊金额">
                </el-table-column>
                <el-table-column
                prop="pres_count"
                align="center"
                label="累计处方数">
                </el-table-column>
                <el-table-column
                prop="pres_amount"
                align="center"
                width="150"
                label="累计处方金额">
                </el-table-column>
            </el-table>
            <pagination v-show="monthTotal>0" :total="monthTotal" :page.sync="monthQuery.page" :limit.sync="monthQuery.limit"
									@pagination="_getDocReportMonthList"/>
        </el-dialog>

        <el-dialog title="提现详情" :visible.sync="dialogBillsVisible">
            <el-table
                v-loading="docMonthLoading"
                :data="tableDataBill"
                border
                style="width: 100%">
                <el-table-column
                prop="bill_sn"
                label="结算编号"
                align="center">
                </el-table-column>
                <el-table-column
                prop="amount"
                align="center"
                label="提现金额"
                width="120">
                </el-table-column>
                <el-table-column
                prop="real_amount"
                align="center"
                label="实际到账金额"
                width="120">
                </el-table-column>
                
                <el-table-column
                prop="status_name"
                align="center"
                width="200"
                label="状态">
                    <template slot-scope="{row}">
                        <div v-if="row.status==2">
                            <el-tooltip placement="top" >
                                <div slot="content">拒绝原因:{{row.reject_reason}}</div>
                                <el-tag :type="row.status==2?'danger':'primary'" style="cursor:pointer">{{row.status_name}}</el-tag>
                                <!-- <span style="display: inline-block;margin-left: 5px;color: #999;">查看原因</span> -->
                            </el-tooltip>
                        </div>
                        
                        <div v-else>
                            <el-tag :type="row.status==2?'danger':'primary'">{{row.status_name}}</el-tag>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="created_at"
                    align="center"
                    label="申请时间">
                </el-table-column>

              
            </el-table>
            <pagination v-show="billTotal>0" :total="billTotal" :page.sync="billsQuery.page" :limit.sync="billsQuery.limit"
									@pagination="_getBillsList"/>
        </el-dialog>

        <el-dialog title="去提现" :visible.sync="dialogTxVisible">
            <el-form ref="form" :model="billsForm" :rules="rules1" label-width="100px">
                
                <el-form-item label="提现金额" prop="amount">
                    <el-input  v-model="billsForm.amount">
                         <el-button slot="append" @click="handleAllTx">全部提现</el-button>
                    </el-input>
                </el-form-item>

                <el-form-item label="银行" prop="bank_id">
                    {{proxyInfo.bank ? proxyInfo.bank.bank_name : '暂未配置'}}
                </el-form-item>

                <el-form-item label="银行卡号" prop="bank_id">
                    {{proxyInfo.bank ? proxyInfo.bank.card_no : '暂未配置'}}
                </el-form-item>

                 <el-form-item label="持卡人姓名" prop="card_name">
                      {{proxyInfo.bank ? proxyInfo.bank.card_name : '暂未配置'}}
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitBillForm()">确认</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>


        <el-dialog title="收益明细" :visible.sync="dialogIncomeVisible">
            <el-table
                v-loading="docMonthLoading"
                :data="tableDataIncome"
                border
                style="width: 100%">
                <el-table-column
                prop="doctor_name"
                label="来源医师"
                align="center">
                </el-table-column>

                <el-table-column
                    prop="order_sn"
                    align="center"
                    label="订单编号"
                    width="180">
                </el-table-column>

                <el-table-column
                prop="doctor_name"
                label="状态"
                align="center">
                <template slot-scope="{row}">
                    <div v-if="row.status == 0">
                        <el-tooltip placement="top" >
                                <div slot="content">订单未完成</div>
                                 <el-tag type="danger">冻结</el-tag>
                                <!-- <span style="display: inline-block;margin-left: 5px;color: #999;">查看原因</span> -->
                            </el-tooltip>
                       
                    </div>
                    <div v-else>
                        <el-tag type="primary">转入余额</el-tag>
                    </div>
                    
                </template>
                    
                </el-table-column>

                <el-table-column
                prop="amount"
                align="center"
                label="收入金额"
                width="120">
                </el-table-column>
                <!-- <el-table-column
                prop="status_name"
                align="center"
                label="状态"
                width="120">
                </el-table-column> -->

                <el-table-column
                prop="type_name"
                align="center"
                label="类型"
                width="120">
                </el-table-column>
                
                <el-table-column
                    prop="created_at"
                    align="center"
                    label="时间">
                </el-table-column>

              
            </el-table>
            <pagination v-show="incomeTotal>0" :total="incomeTotal" :page.sync="incomeQuery.page" :limit.sync="incomeQuery.limit"
									@pagination="_getProxyIncomeList"/>
        </el-dialog>
    </div>
</template>
<script>
import {proxyInfo,addBankCard,updateBankCard,doctorReport,doctorReportMonth,proxyBillsList,postProxyBills,getProxyIncomeList} from "@/api/finance"
import Pagination from '@/components/Pagination'
import { unix } from "moment"
import { mapState } from 'vuex'
export default {
    name: "accountInfo",
    created(){
        this.getProxyInfo()
    },
    components: {
        Pagination

    },
    data(){
        return {
            dateText:(new Date()).getFullYear() + '年' + ((new Date()).getMonth() + 1) + '月' + (new Date()).getDate() + '日',
            bankType:'add',
            bankForm:{},
            dialogQrcodeVisible:false,
            dialogBankVisible:false,
            dialogDocReportVisible:false,
            dialogDocReportMonthVisible:false,
            dialogBillsVisible:false,
            dialogTxVisible:false,
            dialogIncomeVisible:false,
            proxyInfo:{},
            tableData:[],
            tableDataIncome:[],
            docLoading:false,
            docMonthLoading:false,
            total:0,
            incomeTotal:0,
            docReportQuery:{
                page:1,
                limit:20
            },
            tableDataMonth:[],
            tableDataBill:[],
            monthTotal:0,
            billTotal:0,
            monthQuery:{
                page:1,
                limit:20
            },
            incomeQuery:{
                page:1,
                limit:20
            },
            billsQuery:{
                page:1,
                limit:20
            },
            billsForm:{
                amount:'0.00'
            },
            rules:{
                bank_id: [
                    { required: true, message: '请选择银行', trigger: 'chenge' },
                ],
                card_no: [
                    { required: true, message: '请填写银行卡卡号'},
                ],
                card_name: [
                    { required: true, message: '请填写持卡人姓名' },
                ],
            },
            rules1:{
                amount: [
                    { required: true, message: '请填写提现金额' },
                ],
            }
        }
    },
    computed:{
        ...mapState({
            bankOptions:(state) => state.dictionary.bank_options
        })
    },
    mounted(){
        this.$store.dispatch('dictionary/getBankOptions')
    },
    methods:{
        handleIncome(){
            this.dialogIncomeVisible = true
            this._getProxyIncomeList()
        },
        _getProxyIncomeList(){
            this.docMonthLoading = true
            getProxyIncomeList().then(res => {
                this.docMonthLoading = false
                if(200 == res.code){
                    this.tableDataIncome = res.data.list
                    this.incomeTotal = res.data.total
                }
            })
        },
        submitBillForm(){
            if(!this.proxyInfo.bank){
                return this.$message.error('请先添加银行卡！')
            }
            postProxyBills(this.billsForm).then(res=>{
                if(200 == res.code){
                    this.getProxyInfo()
                    this.dialogTxVisible = false;
                    return this.$message.success('申请提现成功！')
                }else{
                    return this.$message.error(res.msg)
                }
            })

        },
        //全部提现
        handleAllTx(){   
            // this.$nextTick(()=>{
                this.billsForm.amount = this.proxyInfo.bills.enableAmount
            // }) 
            
                  
        },
        handleTx(){
            this.dialogTxVisible= true
        },
        handleGoDoc(){
            this.$router.push({
                path:'/proxyDoctor/proxyDoc'
            })
        },
        handleGoProxy(){
            this.$router.push({
                path:'/proxyManage/proxyList'
            })
        },
        submitForm(){
            this.$refs['form'].validate((valid) => {
                if(valid){
                    if(this.bankType == 'add'){
                        addBankCard(this.bankForm).then(res=>{
                            if(200 == res.code){
                                this.proxyInfo.bank = res.data
                                this.dialogBankVisible = false
                                return this.$message.success("银行卡添加成功！")
                            }else{
                          
                                return this.$message.error(res.msg)
                            }
                        }).catch(err => {
                            this.dialogBankVisible = false
                            console.log(err)
                        })
                    }else{
                        
                        updateBankCard(this.bankForm.id,this.bankForm).then(res=>{
                            if(200 == res.code){
                                this.proxyInfo.bank = res.data
                                this.dialogBankVisible = false
                                return this.$message.success("银行卡修改成功！")
                            }else{
                          
                                return this.$message.error(res.msg)
                            }
                        }).catch(err => {
                            this.dialogBankVisible = false
                            console.log(err)
                        })
                    }
                    
                }
            })
        },
        handleBank(type){
            this.bankType = type
            if(type == 'add'){
                this.bankForm = {}
            }else{
                this.bankForm = {
                    id:this.proxyInfo.bank.id,
                    bank_id:this.proxyInfo.bank.bank_id,
                    card_name:this.proxyInfo.bank.card_name,
                    card_no:this.proxyInfo.bank.card_no,
                }
                // this.bankForm = Object.assign({},this.proxyInfo.bank)
            }
            this.dialogBankVisible = true
        },
        
        handleReport(){
            this._getDocReportList()
            this.dialogDocReportVisible = true
        },
        handleReportMonth(){
            this._getDocReportMonthList()
            this.dialogDocReportMonthVisible = true
        },

        handleBills(){
            this._getBillsList()
            this.dialogBillsVisible = true
        },

        _getBillsList(){
            this.docMonthLoading = true
            proxyBillsList(this.billsQuery).then(res => {
                this.docMonthLoading = false
                if(res.code == 200){
                    this.tableDataBill = res.data.list
                    this.billTotal = res.data.total
                }
            })
        },
        _getDocReportMonthList(){
           this.docMonthLoading = true
            doctorReportMonth(this.monthQuery).then(res => {
                this.docMonthLoading = false
                if(res.code == 200){
                    this.tableDataMonth = res.data.list
                    this.monthTotal = res.data.total
                }
            })
        },
        _getDocReportList(){
            this.docLoading = true
            doctorReport(this.docReportQuery).then(res=>{
                this.docLoading = false
                if(res.code == 200){
                    this.tableData = res.data.list
                    this.total = res.data.total
                }
            })
        },
        getProxyInfo(){
           proxyInfo().then(res => {
                if(200 == res.code){
                    this.proxyInfo = res.data
                }
           }) 
        },
        handleQrcode(){
            this.dialogQrcodeVisible = true
        },
        saveQrcode(){
            this.downloadIamge(this.proxyInfo.level_name+"-"+this.proxyInfo.name)
            // const a = document.createElement("a"); // 转换完成，创建一个a标签用于下载
			// 				a.download = "格格健康平台订单支付明细表.xlsx";
			// 				a.href = window.URL.createObjectURL(this.proxyInfo.proxy.qrcode);
			// 				a.click();
			// 				a.remove();
            this.dialogQrcodeVisible = false
        },
        downloadIamge(name) {
            var image = new Image();
            //跨域会导致a标签在部分浏览器中会直接打开新标签页 解决跨域 Canvas 污染问题
            image.setAttribute("crossOrigin", "anonymous");
            image.onload = function () {
                var canvas = document.createElement("canvas");
                canvas.width = image.width;
                canvas.height = image.height;
        
                var context = canvas.getContext("2d");
                context.drawImage(image, 0, 0, image.width, image.height);
                var url = canvas.toDataURL("image/png");
        
                // 生成一个a元素
                var a = document.createElement("a");
                // 创建一个单击事件
                var event = new MouseEvent("click");
        
                // 将a的download属性设置为我们想要下载的图片名称，若name不存在则使用‘下载图片名称’作为默认名称
                a.download = name || "下载图片名称";
                // 将生成的URL设置为a.href属性
                a.href = url;
        
                // 触发a的单击事件
                a.dispatchEvent(event);
            };
 
            image.src = this.proxyInfo.proxy.qrcode
        },

    }
}
</script>
<style scoped lang="scss">
    .con-box{
        padding: 30px;
        box-sizing: border-box;
        background: #fff;
        border-radius: 10px;
        margin-bottom: 20px;
        position: relative;

        .con-box-qrcode{
            position: absolute;
            // width: 100px;
            // height: 30px;
            // border:1px solid red;
            right: 30px;
            top:10px;
            // border-radius: 10px;
        }
        .con-box-yeji{
           display: flex;
           justify-content: space-between;
           .con-box-yeji-title{
                display: flex;
                align-items: center;
                .con-box-yeji-title-txt{
                    font-size: 18px;
                    
                }
                .con-box-yeji-title-date{
                    font-size: 14px;
                    margin-left: 10px;
                }
            }
           .con-box-yeji-info{
                font-size: 14px;
                color: #999;
                cursor: pointer;
           }
        }
        .con-box-bussiness{
            margin-top:25px;
            display: flex;
            justify-content: space-between;
            .con-box-bussiness-item{
                display: flex;
                flex-direction: column;
                align-items: center;
                .con-box-bussiness-item-num{
                    font-size: 18px;
                    font-weight: bold;
                }
                .con-box-bussiness-item-txt{
                    margin-top: 10px;
                    font-size: 14px;
    
                }
            }
        }
        
    }
</style>